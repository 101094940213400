@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import '~tiny-slider/dist/tiny-slider.css';
@import '~glightbox/dist/css/glightbox.css';
@import '~vuetify/dist/vuetify.css';

* {
  margin: 0;
  padding: 0;
}

a,
*:focus {
  outline: 0;
}

html {
  scroll-behavior: smooth;
}

[v-cloak] > * {
  display: none !important;
}

[v-cloak] {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
}

[v-cloak]:after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 5px solid #2980b9;
  border-color: #2980b9 transparent #2980b9 transparent;
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  color: #555;
  font-size: 15px;
  font-weight: 500;
  font-family: poppins;
}

img {
  display: block;
}

header {
  width: 100%;
  background: rgba(255, 255, 255, .95);
  box-shadow: 0 0 15px #555;
  position: fixed;
  z-index: 2;
}

header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  height: 100px;
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
  transition: .3s;
}

header > div > a {
  max-width: 270px;
}

header > div > a > img {
  width: 100%;
}

header > div > nav {
  display: flex;
  align-items: center;
}

header > div > nav > ul {
  display: flex;
  margin-right: 15px;
  list-style: none;
}

header > div > nav > ul > li {
  padding: 0 15px;
}

header > div > nav > ul > li > a {
  display: block;
  color: inherit;
  font-size: 17px;
  text-decoration: none;
  position: relative;
}

header > div > nav > ul > li > a:after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #555;
  transition: .3s;
  position: absolute;
  left: 0;
}

header > div > nav > ul > li > a:hover:after {
  width: 100%;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  line-height: 1;
  padding: 0 25px;
  color: #fff;
  font-size: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: #2980b9;
  border: 0;
  border-radius: 50px 50px 50px 50px;
  transition: .3s;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.button.small {
  height: 35px;
  padding: 0 20px;
  font-size: 13px;
}

.button:hover {
  transform: scale(1.1);
}

.button > * {
  position: relative;
  z-index: 1;
}

.button:after {
  content: "";
  display: block;
  width: 0;
  height: 50px;
  background: #003659;
  transform: skewX(-25deg);
  transition: .3s;
  position: absolute;
}

.button:hover:after {
  width: 150%;
}

.button > span:nth-of-type(2) {
  padding-top: 5px;
  color: #c9dfed;
  font-size: 11px;
}

.lang {
  display: flex;
  position: absolute;
  right: 0;
}

.lang > a {
  margin-right: 20px;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.lang > a {
  margin-right: 20px;
  color: #cbcbcb;
  font-weight: bold;
  text-decoration: none;
}

.lang > a.active {
  color: inherit;
}

#menubutton {
  display: none;
}

main {
  padding-top: 100px;
}

.slider-wrapper {
  background: linear-gradient(300deg, #004b64, #0078ac);
  position: relative;
  overflow: hidden;
}

.tns-outer > button {
  display: none;
}

.slider-wrapper > .tns-outer {
  max-width: 100%;
  padding: 0;
}

.slider article {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 100px 20px;
  box-sizing: border-box;
  transition: .3s;
}

.slider article > div:first-of-type {
  flex-basis: 70%;
  flex-shrink: 0;
  padding: 20px 100px 160px 0;
  color: #fff;
  box-sizing: border-box;
}

.slider article > div > h1 {
  height: 118px;
  margin-bottom: 20px;
  line-height: 1;
  font-size: 40px;
  text-transform: uppercase;
}

.slider article > div > img {
  max-width: 100%;
}

.slider .tns-slide-active article > div > h1 {
  animation: 1s both .75s fadeindown;
  animation-delay: 1s;
}

.slider article > div > p {
  height: 84px;
  color: #fff;
  font-size: 17px;
}

.slider .tns-slide-active article > div > p {
  animation: 1s both 1.25s fadeindown;
}

.slider .tns-slide-active article > div > img {
  animation: 1s both 1.75s fadeinup;
}

.tns-nav {
  display: flex;
  gap: 30px;
  margin: -100px auto 0 auto;
  pointer-events: none;
  position: relative;
  animation: 1s both fadeinup;
}

.tns-nav button {
  width: 15px;
  height: 15px;
  background: #d6d6d6;
  border-radius: 100%;
  pointer-events: auto;
  transition: .3s;
}

.tns-nav .tns-nav-active,
.tns-nav button:hover {
  background: #869791;
}

.carousel-buttons {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 135px;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  animation: 1s both fadeinup;
}

.carousel-buttons > * {
  color: #fff;
  pointer-events: auto;
  transition: .3s;
}

.carousel-buttons > *:first-of-type {
  margin-right: 40px;
  background: #009aff;
}

.carousel-buttons > *:last-of-type {
  display: inline-block;
  text-decoration: none;
  position: relative;
}

.carousel-buttons > *:last-of-type:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  transition: .3s;
  position: absolute;
}

.carousel-buttons > *:last-of-type:hover:after {
  background: #55b5f4;
}

.carousel-buttons > *:last-of-type:hover {
  color: #55b5f4;
}

main hr {
  height: 100px;
  margin-top: -100px;
  border: 0;
  visibility: hidden;
}

article hr {
  display: block;
  max-width: 250px;
  height: 1px;
  margin: 30px auto;
  background: #2980b9;
  border: 0;
  visibility: visible;
}

section p,
.modal ul {
  color: #999;
}

.modal > img {
  max-width: 200px;
}

section > div {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding: 60px 0;
}

section > div > article {
  padding: 20px;
  box-sizing: border-box;
}

section > div > article a {
  color: inherit;
  transition: .3s;
}

section > div > article a:hover {
  color: #2980b9;
}

.top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  font-size: 17px;
}

.top > h1 {
  flex-basis: 100%;
  margin: 20px 20px 0 20px;
  line-height: 1;
  font-size: 38px;
}

.top p:not(:last-child) {
  margin-bottom: 30px;
}

h1 > span {
  color: #2980b9;
}

.top > div {
  flex-basis: 100%;
  margin: 20px;
}

.gray {
  background: #f4f4f4;
}

.companies {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 60px;
  padding: 0 20px;
  box-sizing: border-box;
}

.companies > article {
  width: 25%;
  padding: 30px 0;
  box-sizing: border-box;
}

.companies > article > ul {
  margin: 20px 0;
  font-size: 13px;
  list-style: none;
}

.companies > article > ul > li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.companies > article > ul > li > img {
  width: auto;
  height: 15px;
  margin-right: 10px;
}

.companies > article > ul > li > a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  transition: .3s;
  position: relative;
}

.companies > article > ul > li > a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #555;
  transition: .3s;
  position: absolute;
  top: 20px;
}

.companies > article > ul > li > a:hover:after {
  background: #55b5f4;
}

.companies > article > ul > li > a:hover {
  color: #55b5f4;
}

.companies > article > img {
  max-width: 200px;
}

.borders.half {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px 20px 0 20px;
  box-sizing: border-box;
}

.borders.half .button {
  position: absolute;
  bottom: 30px;
}

.borders.half > article {
  flex-basis: 50%;
  border: solid #d8d8d8;
  box-sizing: border-box;
  position: relative;
}

.borders.half > article:nth-of-type(odd) {
  padding: 30px 30px 80px 0;
  border-width: 0 1px 1px 0;
}

.borders.half > article:nth-of-type(even) {
  padding: 30px 0 80px 30px;
  border-width: 0 0 1px 0;
}

.borders.half > article:nth-of-type(-n+2) {
  padding-top: 0;
}

.borders.half > article:last-of-type {
  flex-basis: 100%;
  border: 0;
}

.borders.half > article > p {
  margin: 30px 0;
  font-size: 15px;
}

.top.third {
  max-width: 1000px;
  margin: auto;
}

.top.third > div {
  flex-basis: calc(33.33% - 40px);
}

.counter-wrapper {
  text-align: center;
  background: url(../images/bg_counter.avif) center no-repeat;
  background-size: cover;
}

.counter-wrapper strong {
  display: block;
  color: #2980b9;
  font-size: 59px;
  font-weight: bold;
}

.counter-wrapper span {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  display: flex;
  align-items: center;
  line-height: 1.2;
  font-size: 17px;
}

h2 > img {
  height: 40px;
  margin-right: 20px;
}

h2 > span > span {
  display: block;
}

h2 > span > span:first-of-type {
  font-weight: normal;
}

h3 {
  margin: 20px 0;
  font-size: 15px;
}

p strong {
  color: #2980b9;
}

.modal {
  display: none;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.modal h2 {
  padding-right: 30px;
}

.modal > ul {
  padding-left: 15px;
}

.gslide-inline .ginlined-content {
  padding: 30px;
}

.contact-us {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contact-us strong {
  flex-basis: 100%;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.contact-wrapper {
  padding-bottom: 100px;
  background: url(../images/bg_contact.avif) center no-repeat;
  background-size: cover;
}

.contact-wrapper > div {
  padding-bottom: 0;
}

.contact-wrapper > div > article {
  width: 100%;
  margin: 40px 20px 0 20px;
  padding: 0;
  background: #fff;
}

.contact-wrapper > div > article > div.contact {
  display: flex;
  flex-wrap: wrap;
}

.contact-wrapper > div > article > div.contact > div {
  flex-basis: 50%;
  margin: 50px 0;
  box-sizing: border-box;
}

.contact-wrapper > div > article > div.contact > div:first-of-type {
  padding: 0 50px;
  border-right: 1px solid #d8d8d8;
}

.contact-wrapper > div > article > div.contact > div:last-of-type {
  display: flex;
  align-items: flex-start;
  padding: 0 50px;
}

.contact-wrapper h1 {
  flex-basis: 100%;
  padding: 50px 50px 0 50px;
  line-height: 1;
  font-size: 38px;
  box-sizing: border-box;
}

.contact-wrapper h2 {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.contact-wrapper p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
  color: #555;
}

.contact-wrapper div > p:last-of-type {
  margin-bottom: 0;
}

.contact-wrapper a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  transition: .3s;
  position: relative;
}

.contact-wrapper a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #555;
  transition: .3s;
  position: absolute;
  top: 20px;
}

.contact-wrapper a:hover {
  color: #55b5f4;
}

.contact-wrapper a:hover:after {
  background: #55b5f4;
}

.contact-wrapper img {
  height: 40px;
  margin: 0 20px 0 0;
}

.people {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.people > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 185px;
  margin: 50px;
  text-align: center;
}

.people img {
  width: 150px;
  height: 150px;
  margin: 20px auto;
  border-radius: 100%;
}

.people strong {
  font-size: 17px;
}

form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

form > p {
  margin-bottom: 20px;
}

input,
textarea {
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #d8d8d8;
  transition: .3s;
}

input:focus,
textarea:focus {
  border-color: #2980b9;
}

input {
  width: calc(50% - 25px);
  margin-bottom: 50px;
}

.inputs-2,
.inputs-3 {
  display: flex;
  width: 100%;
}

.inputs-2 > input {
  width: calc(50% - 15px);
}

.inputs-3 > input {
  width: calc(33.33% - 15px);
}

input {
  margin-bottom: 50px;
}

input:not(:first-of-type) {
  margin-left: 30px;
}

textarea {
  flex-basis: 100%;
  min-height: 200px;
  resize: vertical;
}

form > div:last-child {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 50px;
  box-sizing: border-box;
}

iframe {
  display: block;
  width: 100%;
  max-width: 1160px;
  ;
  height: 593px;
  margin: 0 auto 0 auto;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d8d8d8;
}

footer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  min-height: 100px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

footer > div > a {
  max-width: 150px;
}

footer > div > a > img {
  width: 100%;
}

.alert {
  max-width: 700px;
  margin: 0 auto 50px auto;
  padding: 20px;
}

.alert > article {
  padding: 20px;
  background: #2980b9;
}

.alert > article > p {
  color: #fff;
}

.alert a {
  color: inherit;
  transition: .3s;
}

.alert a:hover {
  opacity: .5;
}

.glightbox-container .gslider {
  background: #2980b9cc;
}

.glightbox-clean .gslide-media {
  box-shadow: none;
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeinup {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1400px) {
  header > div {
    position: relative;
  }

  .lang {
    top: 0;
    left: 20px;
  }

  .slider article > div:first-of-type {
    padding: 20px 100px 160px 0;
  }

  .slider article > div > h1 {
    font-size: 40px;
  }

  .contact-wrapper {
    padding-bottom: 0;
    background: #fff;
  }

  .contact-wrapper > div {
    padding: 0;
  }

  .contact-wrapper > div > article {
    margin: 20px 0 0 0;
  }

  .contact-wrapper > div > article > div.contact > div:first-of-type {
    padding-left: 20px;
  }

  .contact-wrapper > div > article > div.contact > div:last-of-type {
    padding-right: 20px;
  }

  .contact-wrapper h1 {
    padding: 50px 20px 0 20px;
  }

  iframe {
    max-width: 100%;
  }
}

@media (max-width: 1250px) {
  .companies > article {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  header > div > a {
    max-width: 150px;
  }

  header > div > nav > ul {
    margin-right: 10px;
  }

  header > div > nav > ul > li {
    padding: 0 10px;
  }

  .counter-wrapper strong {
    font-size: 40px;
  }

  .counter-wrapper span {
    font-size: 17px;
  }

  .contact-wrapper > div > article {
    margin: 20px 0 50px 0;
  }

  .contact-wrapper > div > article > div.contact > div:first-of-type,
  .contact-wrapper > div > article > div.contact > div:last-of-type {
    flex-basis: 100%;
    margin: 50px 0 0 0;
    padding: 0 20px;
    border: 0;
  }
}

@media (max-width: 750px) {
  header > div {
    height: 50px;
  }

  #menubutton {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    cursor: pointer;
  }

  nav > .button {
    display: none;
  }

  #menucontent {
    flex-direction: column;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    right: 0;
    background: #2980b9;
  }

  #menucontent > li {
    padding: 0;
  }

  #menucontent > li > a {
    display: block;
    line-height: 50px;
    padding: 0 20px;
    color: #fff;
    border-bottom: 1px solid #fff;
    transition: .3s;
  }

  #menucontent > li > a:hover {
    background: #009aff;
  }

  header > div > nav > ul > li > a:after {
    display: none;
  }

  .lang {
    top: 13px;
    left: auto;
    right: 50px;
  }

  main {
    padding-top: 50px;
  }

  .slider article {
    flex-direction: column;
    padding: 50px 20px 0 20px;
  }

  .slider article > div:first-of-type {
    padding: 0 0 30px 0;
  }

  .slider article > div:last-of-type {
    padding-bottom: 130px;
  }

  .top > h1,
  .contact-wrapper h1,
  .slider article > div > h1 {
    font-size: 30px;
  }

  .slider article > div > h1 {
    height: 90px;
  }

  .slider article > div > p {
    height: 140px;
  }

  .slider article > div > img {
    max-width: 200px;
    margin-bottom: 100px;
  }

  .carousel-buttons {
    margin-bottom: 100px;
    bottom: 0;
  }

  .tns-nav {
    margin-top: -50px;
  }

  .top.third > div {
    flex-basis: 100%;
  }

  main hr {
    height: 50px;
    margin-top: -50px;
  }

  .borders.half .button {
    position: relative;
    bottom: 0;
  }

  .borders.half > article {
    flex-basis: 100%;
  }

  .borders.half > article:nth-of-type(2) {
    padding-top: 30px;
  }

  .borders.half > article:nth-last-of-type(2) {
    padding-bottom: 30px;
    border-bottom: 1px solid #d8d8d8;
  }

  .borders.half > article:nth-of-type(odd),
  .borders.half > article:nth-of-type(even) {
    margin-bottom: 0;
    padding: 30px 0;
    border-right: 0;
  }

  .inputs-2,
  .inputs-3 {
    flex-wrap: wrap;
  }

  .inputs-2 > input,
  .inputs-3 > input {
    width: 100%;
  }

  input:not(:first-of-type) {
    margin-left: 0;
  }

  footer > div {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .companies > article {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .contact-wrapper img {
    margin: 0 0 10px 0;
  }

  .contact-wrapper p > span {
    flex-basis: 100%;
  }
}

@media (min-width: 751px) {
  #menucontent {
    display: flex !important;
  }
}